<template>
  <v-card
    v-if="item"
    class="mt-3"
    :loading="loading"
    :hover="!open"
    :flat="open"
    :disabled="disabled"
    min-height="90"
    min-width="150"
    :style="disabled ? 'filter: grayscale(1);' : ''"
  >
    <template slot="progress">
      <v-progress-linear color="primary" height="10" indeterminate></v-progress-linear>
    </template>

    <div style="height: 1px">
      <v-btn
        v-if="favoriteButton && !disabled"
        depressed
        fab
        x-small
        :color="favored ? 'secondary' : 'grey lighten-2'"
        :style="`position: absolute; ${!open ? 'right: -7px;' : ''} top: -7px; z-index: 9;`"
        @click="
          !favored
            ? saveFavorite({ item: { productId: item.id } })
            : deleteFavorite(item.id)
        "
      >
        <v-icon :color="favored ? 'primary' : 'white'">mdi-cards-heart</v-icon>
      </v-btn>

      <div class="warning attached-label">
        {{ getCurrency + " " + item.unitPrice }}
      </div>
    </div>

    <v-carousel v-if="item.images && item.images.length > 1" height="130" hide-delimiters>
      <v-carousel-item
        v-for="(image, i) in item.images"
        :key="i"
        :src="`${getApiBaseUrl}/files/image?path=stores/_${getOpenStore.uuid}/${image}`"
        @click="() => $emit('click:card', item)"
      >
      <!-- :src="`${getApiBaseUrl}/image/255/0.9/?path=stores/_${getOpenStore.uuid}/${image}`" -->
      </v-carousel-item>
      <!-- <v-carousel-item
        v-for="(image, i) in item.images"
        :key="i"
        :src="`${getApiBaseUrl}/files/?path=images/${image}`"
        @click="() => $emit('click:card', item)"
      >
      </v-carousel-item> -->
    </v-carousel>

    <v-img
      v-if="item.images && item.images.length === 1"
      height="130"
      :src="getFirstImage"
      @click="() => $emit('click:card', item)"
    ></v-img>

    <v-divider v-if="showName || showDescription" style="border-style: dashed" />
    <v-card-title
      v-if="showName"
      class="px-1 body-1"
      style="line-height: 18px"
      v-text="item.name"
      @click="() => $emit('click:card', item)"
    />

    <div class="d-flex justify-space-between">
      <!-- <v-divider class="mt-4 ml-1" style="border-style: dashed;" /> -->
      <div v-if="showRating" class="text-right d-flex mt-1 mx-1">
        <v-rating
          :value="item.rate"
          color="amber"
          dense
          half-increments
          readonly
          size="12"
          class="ma-0 pa-0"
        ></v-rating>
        <div class="grey--text mt-1 ml-1" style="font-size: 10pt">
          {{ item.rate }} ({{ item.votes }})
        </div>
      </div>
    </div>

    <div v-if="showDescription" class="pa-1 caption">
      {{ item.description }}
    </div>

    <div class="text-center ma-1" v-if="showTags">
      <tags :items="item.tags" />
    </div>

    <v-divider class="mt-1" />

    <cart-buttons :item="item" :warning="warning" />

    <v-divider class="mt-1" v-if="warning" />

    <div v-if="warning" class="red--text body-2 pa-1">
      {{ $t("errors.outOfStock", { available: item.stock }) }}
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Tags from "@/common/components/Tags";
import CartButtons from "@/views/orders/CartButtons";

export default {
  name: "ProductCard",
  components: { Tags, CartButtons },
  props: {
    item: Object,
    open: Boolean,
    favored: Boolean,
    disabled: Boolean,
    warning: Boolean,
    favoriteButton: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showName: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showDescription: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showTags: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showRating: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({
    loading: false,
  }),
  mounted() {
    this.setReactivityParams({
      resource: "PRODUCTS",
      scope: "store/product",
      key: `${this.getStoreIdParam}/${this.item.id}`,
    });
    this.$sub("reactivity/PRODUCTS", (payload) => {
      console.log("reactivity/PRODUCTS:", payload);
      this.loading = true;
      this.refreshProducts({
        storeId: this.getStoreIdParam,
        productIds: [this.item.id],
      }).then(() => (this.loading = false));
      console.log("reactivity/PRODUCTS:", payload);
    });
  },
  computed: {
    ...mapGetters("stores", ["getOpenStore", "getStoreById"]),

    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    getStore() {
      return this.getStoreById(this.getStoreIdParam);
    },
    getFirstImage() {
      if (this.notEmptyArray(this.item.images)) {
        return `${this.getApiBaseUrl}/files/image?path=stores/_${this.getStore.uuid}/${this.item.images[0]}`;
      }

      return null;
    },
    getCurrency() {
      const region = this.getOpenStore ? this.getOpenStore.region : null;
      return region ? region.currency : "YR";
    },
  },
  methods: {
    ...mapActions("favorites", ["saveFavorite", "deleteFavorite"]),
    ...mapActions("ui", ["setReactivityParams", "clearReactivityParams"]),
    ...mapActions("categories", ["refreshProducts"]),

    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 1500);
    },
  },
};
</script>
