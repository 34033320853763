<template>
  <div v-if="notEmptyArray(items)">
    <v-chip color="grey lighten-4" class="mr-1" v-for="(item, i) in items" :key="i" v-text="item" small label />
  </div>
</template>

<script>
export default {
  name: "Tags",
  props: {
    items: Array,
  },
};
</script>
